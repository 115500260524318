import React from 'react';
import { Badge } from '../../../../components/badge';
import { RoccoLink } from '../../../../components/link/RoccoLink';

interface UserMenuElementProps {
    icon?: React.ReactNode;
    title: string;
    to?: string;
    onClick?: () => void;
    badgeCount?: number;
    external?: boolean;
}

export const UserMenuElement = (props: UserMenuElementProps) => {
    const {
        icon,
        title,
        to,
        badgeCount = 0,
        onClick,
        external = false,
    } = props;

    const commonProps = {
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (onClick) {
                e.preventDefault();
                onClick();
            }
        },
        className:
            'flex h-8 items-center justify-between px-2 text-gray-900 hover:opacity-70 hover:text-gray-900',
    };

    const content = (
        <>
            <div className="flex items-center gap-x-2">
                <span className="size-4.5 flex items-center justify-start">
                    {icon}
                </span>
                <span className="text-sm">{title}</span>
            </div>
            {badgeCount > 0 && (
                <Badge
                    size="centeredCompact"
                    text={badgeCount > 9 ? 'small' : 'default'}
                >
                    {badgeCount > 9 ? '9+' : badgeCount}
                </Badge>
            )}
        </>
    );

    return (
        <div>
            {external ? (
                <a href={to || '#'} {...commonProps}>
                    {content}
                </a>
            ) : (
                <RoccoLink to={to || '#'} {...commonProps}>
                    {content}
                </RoccoLink>
            )}
        </div>
    );
};
