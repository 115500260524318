import React from 'react';
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from '../../../../components/avatar';
import { Badge } from '../../../../components/badge';
import MobileProfileMenu from '../MobileProfileMenu';
import type { HeaderNavLinks, HeaderUserData } from '../Header';

interface MobileUserMenuProps {
    userData: HeaderUserData;
    profileLinks: HeaderNavLinks;
    onLogout: () => void;
}

export const MobileUserMenu = (props: MobileUserMenuProps) => {
    const { userData, profileLinks, onLogout } = props;
    const {
        avatarSrc = '',
        firstName,
        lastName = '',
        notificationsCount,
    } = userData;

    const [showMenu, setShowMenu] = React.useState(false);

    return (
        <div>
            <div className="relative">
                <Avatar
                    size="square40"
                    role="button"
                    aria-label="My Profile"
                    onClick={() => {
                        setShowMenu(state => !state);
                    }}
                >
                    <AvatarImage src={avatarSrc} />
                    <AvatarFallback>
                        {firstName[0]}
                        {lastName[0]}
                    </AvatarFallback>
                </Avatar>

                {notificationsCount > 0 ? (
                    <div className="absolute -right-1 -top-1 flex">
                        <Badge
                            size="centeredCompact"
                            text={notificationsCount > 9 ? 'small' : 'default'}
                        >
                            {notificationsCount > 9 ? '9+' : notificationsCount}
                        </Badge>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {showMenu && (
                <MobileProfileMenu
                    userData={userData}
                    profileLinks={profileLinks}
                    setShowProfileMenu={setShowMenu}
                    showProfileMenu={showMenu}
                    onLogout={onLogout}
                />
            )}
        </div>
    );
};
