import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { useAtom } from 'jotai';
import { cn } from '../../../utils/cn';
import { ReactComponent as SearchIcon } from '../../../icons/svg/search.svg';
import { Button } from '../../../components/button';
import { CategoriesMenu } from './CategoriesMenu';
import { CountrySelectMenu } from './CountrySelectMenu';
import { UserCart } from './UserCart';
import {
    _headerVariants,
    HeaderCartInfo,
    type HeaderCountryElement,
    type HeaderNavLinks,
    type HeaderUserData,
} from './Header';
import { WarningBanner } from '../../../ui/warning-banner/components/WarningBanner';
import { DesktopUserMenu } from './user-menu/DesktopUserMenu';
import { uiStateAtomSearchClicked } from '../states/ui-states-search-clicked';
import { LogoAnimated } from '../../../components/logo-animated/LogoAnimated';
import { RoccoLink } from '../../../components/link/RoccoLink';

const _headerIconVariants = cva('', {
    variants: {
        fill: {
            dark: 'fill-gray-900',
            light: 'fill-white',
            none: 'fill-none',
        },
        outline: {
            default: '',
            light: 'stroke-1 stroke-white',
            dark: 'stroke-1 stroke-gray-900',
        },
    },
    defaultVariants: {
        fill: 'dark',
        outline: 'default',
    },
});

interface DesktopHeaderProps extends VariantProps<typeof _headerVariants> {
    userData: HeaderUserData;
    primaryLinks: HeaderNavLinks;
    currentCountryCode: string;
    countries: HeaderCountryElement[];
    currentUrl: string;
    onLogout: () => void;
    onSearchClick?: () => void;
    onUserCartClick?: () => void;
    onLoginClick: () => void;
    additionalUserMenuItems?: HeaderNavLinks;
    warningMessages?: string[];
    isLegacyBrokenRemMode?: boolean;
    cartInfo: HeaderCartInfo;
}

export const DesktopHeader = (props: DesktopHeaderProps) => {
    const {
        color,
        userData,
        primaryLinks,
        currentCountryCode,
        countries,
        currentUrl,
        onLogout,
        onUserCartClick,
        onLoginClick,
        additionalUserMenuItems,
        warningMessages,
        isLegacyBrokenRemMode,
        cartInfo,
    } = props;

    const mode = color === 'transparent' ? 'float' : 'default';

    const menuColor = color == 'transparent' ? 'light' : 'dark';
    const outlineClass = _headerIconVariants({
        outline: menuColor,
        fill: 'none',
    });

    const [, setSearchClicked] = useAtom(uiStateAtomSearchClicked);

    const handleSearchClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        setSearchClicked(true);
        if (currentUrl.includes('/search')) {
            e.preventDefault();
        }
    };

    const { cartCount } = cartInfo;

    return (
        <div className={cn(_headerVariants({ color, mode }), 'rocco-header')}>
            <div className="items-center justify-between pb-6 px-12 pt-8 flex h-24 qhd:mx-auto qhd:max-w-[1920px] qhd:px-12">
                {/* Categories Menu */}
                <div className="flex-1">
                    <CategoriesMenu
                        color={menuColor}
                        links={primaryLinks}
                        currentUrl={currentUrl}
                    />
                </div>

                {/* Logo */}
                <div className="-mt-2 -ml-20 shrink-0">
                    <RoccoLink
                        to="/"
                        aria-label="Home"
                        className={
                            color == 'transparent'
                                ? 'text-gray-50'
                                : 'text-gray-900'
                        }
                    >
                        <LogoAnimated
                            size={2.4}
                            isLegacyBrokenRemMode={isLegacyBrokenRemMode}
                            color={menuColor}
                        />
                    </RoccoLink>
                </div>

                <div className="flex flex-1 items-start justify-end gap-x-9">
                    <div className="flex gap-x-18">
                        <div className="flex items-center gap-x-8 relative top-0.5">
                            <RoccoLink
                                to="/search"
                                prefetch="intent"
                                onClick={handleSearchClick}
                            >
                                <SearchIcon
                                    className={cn(
                                        'relative -top-[3px] left-[3px] size-6',
                                        outlineClass,
                                    )}
                                />
                            </RoccoLink>

                            <CountrySelectMenu
                                color={menuColor}
                                currentCountryCode={currentCountryCode}
                                countries={countries}
                            />
                        </div>

                        <RoccoLink
                            to="/become-a-pro"
                            className={cn(
                                'pt-[3px] text-base font-medium decoration-2 underline-offset-8 hover:underline',
                                color === 'transparent'
                                    ? 'text-gray-50 hover:text-gray-50'
                                    : 'text-gray-900 hover:text-gray-900',
                            )}
                        >
                            Become a Pro
                        </RoccoLink>
                    </div>

                    {userData.isAuthorized ? (
                        <div className="flex gap-x-10">
                            <div className="flex gap-x-7">
                                <UserCart
                                    fill={
                                        menuColor === 'dark' ? 'dark' : 'light'
                                    }
                                    onUserCartClick={() => {
                                        onUserCartClick?.();
                                    }}
                                    cartInfo={cartInfo}
                                />
                            </div>

                            <DesktopUserMenu
                                userData={userData}
                                color={menuColor}
                                onLogout={onLogout}
                                additionalUserMenuItems={
                                    additionalUserMenuItems
                                }
                            />
                        </div>
                    ) : (
                        <div className="flex gap-x-8">
                            {cartCount > 0 ? (
                                <div className="flex items-center gap-x-8 relative top-0.5">
                                    <UserCart
                                        fill={
                                            menuColor === 'dark'
                                                ? 'dark'
                                                : 'light'
                                        }
                                        onUserCartClick={() => {
                                            onUserCartClick?.();
                                        }}
                                        cartInfo={cartInfo}
                                    />
                                </div>
                            ) : null}
                            <RoccoLink
                                to={'#'}
                                onClick={e => {
                                    e.preventDefault();
                                    onLoginClick();
                                }}
                                aria-label="Log In"
                                className={cn(
                                    'pt-[3px] text-base font-medium decoration-2 underline-offset-8 hover:underline',
                                    color === 'transparent'
                                        ? 'text-gray-50 hover:text-gray-50'
                                        : 'text-gray-900 hover:text-gray-900',
                                )}
                            >
                                Log In
                            </RoccoLink>
                            <div className="-mt-1.5">
                                <Button
                                    color={
                                        color === 'transparent'
                                            ? 'glassy'
                                            : 'primary'
                                    }
                                    inverted={color === 'transparent'}
                                    weight="default"
                                    size={'larger'}
                                    onClick={onLoginClick}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {!!warningMessages?.length && (
                <WarningBanner alerts={warningMessages} />
            )}
        </div>
    );
};
