import { useEffect, useRef, useState } from 'react';
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from '../../../components/avatar';
import { ReactComponent as ChevronRightIcon } from '../../../icons/svg/arrow/arrow-chevron-right.svg';
import { Badge } from '../../../components/badge';
import type { HeaderNavLinks, HeaderUserData } from './Header';
import { RoccoLink } from '../../../components/link/RoccoLink';
import { Accordion, AccordionContent } from '../../../components/accordion';
import { AccordionItem, AccordionTrigger } from '../../../components/accordion';
import { PagesEnum } from '@archipro-website/web/app/utils-rocco/route-config';

interface MobileProfileMenuProps {
    userData: HeaderUserData;
    profileLinks: HeaderNavLinks;
    showProfileMenu: boolean;
    setShowProfileMenu: (val: boolean) => void;
    onLogout: () => void;
}

/**
 * Renders a menu that can be open with a sliding motion.
 * Clicking outside the menu will automatically close it.
 */
const MobileProfileMenu = (props: MobileProfileMenuProps) => {
    const {
        userData,
        profileLinks,
        showProfileMenu,
        setShowProfileMenu,
        onLogout,
    } = props;

    const { firstName, lastName = '', avatarSrc, professionals } = userData;

    const [draggedStartY, setDraggedStartY] = useState(0);
    const [draggedDistance, setDraggedDistance] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [translateY, setTranslateY] = useState('100%');

    const menuRef = useRef<HTMLDivElement>(null);
    const transitionTimeout = useRef<ReturnType<typeof setTimeout> | null>(
        null,
    );

    // Captures the initial touch position
    const startDrag = (e: React.TouchEvent<HTMLElement>) => {
        const touch = e.touches[0];
        setDraggedStartY(touch?.clientY || 0);
    };

    // Handles the dragging movement and updates the menu's vertical position
    const dragging = (e: React.TouchEvent<HTMLElement>) => {
        const touch = e.touches[0];
        if (touch) {
            const distance = touch.clientY - draggedStartY;
            // Limit the upward movement to -80px and downward movement to positive values
            const clampedDistance = Math.max(
                -80,
                Math.min(window.innerHeight, distance),
            );
            setDraggedDistance(clampedDistance);
            setTranslateY(`${clampedDistance}px`);
        }
    };

    // Determines whether to close the menu based on drag distance
    const endDrag = () => {
        if (draggedDistance > 96) {
            setShowProfileMenu(false);
        } else if (draggedDistance > 0) {
            setTranslateY('0');
        }
        setDraggedDistance(0);
    };

    // Closes the menu with a slight delay for the exit animation
    const closeMenu = () => {
        setTranslateY('100%');
        transitionTimeout.current = setTimeout(() => {
            setShowProfileMenu(false);
            transitionTimeout.current = null;
        }, 200);
    };

    // Updates the menu's position when showProfileMenu changes
    useEffect(() => {
        if (showProfileMenu) {
            // First make the element visible
            setIsVisible(true);
            // Then trigger the animation in the next frame
            requestAnimationFrame(() => {
                setTranslateY('0');
            });
        } else {
            setTranslateY('100%');
            // Delay hiding the element until after the animation
            const timeout = setTimeout(() => {
                setIsVisible(false);
            }, 200); // Match your transition duration
            return () => clearTimeout(timeout);
        }
    }, [showProfileMenu]);

    return (
        <main
            className="fixed inset-x-0 top-0 z-100 h-screen w-screen text-gray-900 overflow-scroll overscroll-y-none scrollbar-hide"
            style={{
                display: isVisible ? 'block' : 'none',
                touchAction: 'none', // Prevent default touch behaviors
            }}
        >
            {/* Transparent button to close the menu */}
            <button
                className="block h-20 w-full"
                onClick={closeMenu}
                aria-label="Close profile menu"
            ></button>

            {/* Menu container with slide-up animation */}
            <div
                className="h-screen w-screen rounded-t-2xl overflow-hidden
                bg-gray-100/85 backdrop-blur-lg transition-transform delay-0 duration-200 ease-in-out will-change-transform"
                style={{
                    transform: `translate3d(0, ${translateY}, 0)`,
                    WebkitTransform: `translate3d(0, ${translateY}, 0)`,
                    touchAction: 'none', // Prevent default touch behaviors
                }}
                ref={menuRef}
            >
                {/* Drag handle to allow closing the menu */}
                <button
                    className="flex w-full items-center justify-center pb-8 pt-3"
                    onTouchStart={startDrag}
                    onTouchMove={dragging}
                    onTouchEnd={endDrag}
                    aria-label="Drawer Handle"
                >
                    <span className="h-1 w-9 rounded bg-gray-500 opacity-30"></span>
                </button>

                {/* Container for the menu content, height is calculated as the full screen height minus the top gap (h-20) */}
                <div className="h-[calc(100dvh-5rem)] overflow-y-auto">
                    {/* User Profile Information */}
                    <RoccoLink
                        to="/member/settings/edit-profile"
                        className="flex items-center gap-x-5 px-6"
                    >
                        <div className="text-gray-50">
                            <Avatar size="square64">
                                <AvatarImage src={avatarSrc} />
                                <AvatarFallback>
                                    {firstName[0]}
                                    {lastName[0]}
                                </AvatarFallback>
                            </Avatar>
                        </div>
                        <h2 className="text-xl font-medium">
                            {firstName} {lastName}
                        </h2>
                    </RoccoLink>

                    {/* Profile Navigation Links */}
                    <nav className="mt-8">
                        {profileLinks.links.map(item => (
                            <ProfileMenuLink
                                title={item.title}
                                link={item.to}
                                key={item.title}
                                badgeCount={item.badgeCount}
                            />
                        ))}

                        <ProfileMenuLink
                            title={'Home Design Evening'}
                            link={PagesEnum.HOME_DESIGN_EVENING}
                        />

                        <ProfileMenuLink
                            title={'Log out'}
                            link={'#'}
                            asButton
                            onClick={e => {
                                e.preventDefault();
                                onLogout();
                            }}
                        />

                        {/* Professional Accounts Section */}
                        {professionals?.length > 0 && (
                            <div className="mt-6">
                                <h3 className="px-6 py-4 text-2.5 font-medium text-gray-500">
                                    View business inbox:
                                </h3>

                                {professionals.map(item => (
                                    <ProfileMenuLinkButton
                                        key={item.id}
                                        title={item.title}
                                        link={`/business/${item.urlSegment}/inbox`}
                                        avatarSrc={item.logo}
                                        badgeCount={item.unreads}
                                    />
                                ))}
                            </div>
                        )}
                    </nav>

                    {/* Placeholder for bottom browser navigation */}
                    <div className="h-44"></div>
                </div>
            </div>
        </main>
    );
};

interface ProfileMenuLinkProps {
    badgeCount?: number;
    title: string;
    link: string;
    asButton?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    avatarSrc?: string;
    theme?: 'link' | 'profile';
}

/**
 * Renders a navigation link or button inside the profile menu.
 * It can display a badge for notifications and supports different display themes.
 *
 * @note: If the link is a button, it will prevent the default action of following the link.
 * @note: Profile links are not from web/web-platform, so use <a> to handle the link.
 */
const ProfileMenuLink = ({
    badgeCount,
    title,
    link,
    asButton = false,
    onClick,
}: ProfileMenuLinkProps) => (
    <a
        className="flex items-center justify-between border-b border-b-black/10 px-6 py-4 text-gray-900 hover:text-gray-900"
        href={link}
        role={asButton ? 'button' : 'link'}
        onClick={e => {
            onClick && onClick(e);
            if (asButton) {
                e.preventDefault();
            }
        }}
    >
        <span className="text-base">{title}</span>
        <div className="flex items-center">
            {!!badgeCount && (
                <Badge
                    size="centeredCompact"
                    text={badgeCount > 9 ? 'small' : 'default'}
                >
                    {badgeCount > 9 ? '9+' : badgeCount}
                </Badge>
            )}
            <ChevronRightIcon className="ml-2 size-3" />
        </div>
    </a>
);

const ProfileMenuLinkButton = ({
    badgeCount,
    title,
    avatarSrc,
    onClick,
    link,
}: ProfileMenuLinkProps) => (
    <Accordion type="single" collapsible>
        <AccordionItem value={title} variant="lightBorder">
            <div
                className="px-6"
                onClick={onClick}
                aria-label={`Dropdown for ${title}`}
                role="button"
            >
                <AccordionTrigger startDirection="right">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-x-5">
                            <Avatar size="square64">
                                <AvatarImage src={avatarSrc} />
                                <AvatarFallback>{title[0]}</AvatarFallback>
                            </Avatar>
                            <div className="text-xl font-medium">{title}</div>
                        </div>
                    </div>
                </AccordionTrigger>
            </div>

            <AccordionContent>
                <a
                    href={link}
                    className="flex justify-between items-center border-t border-t-black/10 px-6 pt-4"
                >
                    <span>Inbox</span>
                    {!!badgeCount && (
                        <Badge size="centeredCompact" text="default">
                            {badgeCount > 9 ? '9+' : badgeCount}
                        </Badge>
                    )}
                </a>
            </AccordionContent>
        </AccordionItem>
    </Accordion>
);

export default MobileProfileMenu;
