import { ReactComponent as ChevronRightIcon } from '../../../icons/svg/arrow/arrow-chevron-right.svg';
import { ReactComponent as CloseIcon } from '../../../icons/svg/close.svg';
import { RoccoLink } from '../../../components/link/RoccoLink';
import type { HeaderNavLinks } from './Header';

interface MobileNavMenuProps {
    primaryLinks: HeaderNavLinks;
    secondaryLinks: HeaderNavLinks;
    isAuthorized: boolean;
    close: () => void;
    currentUrl: string;
    logout: () => void;
}

export const MobileNavMenu = (props: MobileNavMenuProps) => {
    const {
        primaryLinks,
        secondaryLinks,
        close,
        isAuthorized,
        currentUrl,
        logout,
    } = props;

    const encodedRedirect = encodeURIComponent(currentUrl);

    return (
        <div className="h-screen overflow-y-scroll overscroll-y-none bg-gray-50 scrollbar-hide">
            <main className="min-h-screen w-screen overscroll-y-none px-6">
                <div className="py-6">
                    <CloseIcon
                        role="button"
                        onClick={close}
                        aria-label="Close Navigation Menu"
                    />
                </div>
                <nav>
                    <ul className="flex flex-col">
                        {primaryLinks.links.map(item => (
                            <li key={item.title}>
                                <RoccoLink
                                    className="flex items-center justify-between border-b py-2"
                                    to={item.to}
                                >
                                    <span className="text-8 leading-[1.3]">
                                        {item.title}
                                    </span>
                                    <ChevronRightIcon className="size-5" />
                                </RoccoLink>
                            </li>
                        ))}
                    </ul>
                    <ul className="mt-11 flex flex-col gap-3">
                        {secondaryLinks.links.map(item => (
                            <li key={item.title}>
                                <RoccoLink
                                    to={item.to}
                                    isNonRemixRoute={item.isNonRemixRoute}
                                >
                                    <span className="text-lg text-gray-500 tracking-wide">
                                        {item.title}
                                    </span>
                                </RoccoLink>
                            </li>
                        ))}
                    </ul>
                </nav>
                <section className="mt-12 flex justify-between gap-2">
                    {isAuthorized ? (
                        // This style of button is only used once in the app for now
                        <button
                            onClick={logout}
                            className="text-lg bg-transparent tracking-wide py-4 text-black flex-1
                            rounded-full text-center border border-black"
                        >
                            Log out
                        </button>
                    ) : (
                        <>
                            <RoccoLink
                                to={`/login?redirect=${encodedRedirect}`}
                                className="text-lg bg-transparent tracking-wide py-4 text-black flex-1
                                rounded-full text-center border border-black"
                            >
                                Log in
                            </RoccoLink>

                            <RoccoLink
                                className="text-lg bg-black tracking-wide py-4 text-white flex-1
                                rounded-full text-center border border-white"
                                to={`/login?redirect=${encodedRedirect}`}
                            >
                                Sign up
                            </RoccoLink>
                        </>
                    )}
                </section>
            </main>

            {/*sometime the nav menu of mobile browser will float on bottom*/}
            {/*this component is to take the place*/}
            <div className="h-24"></div>
        </div>
    );
};
